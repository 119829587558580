import { MantineProvider } from '@mantine/core';
import { Web3Modal } from '@web3modal/react';
import type { AppProps } from 'next/app';
import { memo } from 'react';
import { WagmiConfig } from 'wagmi';

import { loadEnv } from '../src/env';
import { ethereumClient, wagmiClient } from '../src/utils/wallet-connect';

loadEnv(); // Load ENV on entry to exit early if there are any issues

export default function App({ Component, pageProps }: AppProps) {
  const MemoizedComponent = memo(Component);

  return (
    <>
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          /** Put your mantine theme override here */
          colorScheme: 'dark',
        }}
      >
        <WagmiConfig client={wagmiClient}>
          <MemoizedComponent {...pageProps} />
          <Web3Modal projectId={loadEnv().NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID} ethereumClient={ethereumClient} />
        </WagmiConfig>
      </MantineProvider>
    </>
  );
}
