import { z } from 'zod';

/**
 * Specify your client-side environment variables schema here.
 * This way you can ensure the app isn't built with invalid env vars.
 * To expose them to the client, prefix them with `NEXT_PUBLIC_`.
 */
export const schema = z.object({
  NEXT_PUBLIC_ENVIRONMENT: z
    .literal('development')
    .or(z.literal('staging'))
    .or(z.literal('production'))
    .or(z.literal('test'))
    .or(z.literal('production-testnets')),
  NEXT_PUBLIC_OEV_RELAY_API_URL: z.string(),
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_LOG_LEVEL: z.literal('debug').or(z.literal('info')).or(z.literal('error')),
  NEXT_PUBLIC_LOG_TYPE: z.literal('pretty').or(z.literal('json')).or(z.literal('hidden')),
  NEXT_PUBLIC_LOG_STYLING: z.literal('on').or(z.literal('off')),
  NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID: z.string(),
});

/**
 * You can't destruct `process.env` as a regular object, so you have to do
 * it manually here. This is because Next.js evaluates this at build time,
 * and only used environment variables are included in the build.
 */
const _env = {
  NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  NEXT_PUBLIC_OEV_RELAY_API_URL: process.env.NEXT_PUBLIC_OEV_RELAY_API_URL,
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
  NEXT_PUBLIC_LOG_LEVEL: process.env.NEXT_PUBLIC_LOG_LEVEL,
  NEXT_PUBLIC_LOG_TYPE: process.env.NEXT_PUBLIC_LOG_TYPE,
  NEXT_PUBLIC_LOG_STYLING: process.env.NEXT_PUBLIC_LOG_STYLING,
  NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
};

let env: z.infer<typeof schema> | undefined;

export const loadEnv = () => {
  if (env) {
    return env;
  }

  const parseResult = schema.safeParse(_env);

  if (!parseResult.success) {
    throw new Error(`Invalid environment variables:\n, ${JSON.stringify(parseResult.error.format())}`);
  }

  env = parseResult.data;
  return env;
};
