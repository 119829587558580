import { deployments as deploymentsJson, deploymentsDev as deploymentsDevJson } from 'oev-contracts';
import { z } from 'zod';

import { loadEnv } from './env';

const deploymentSchema = z
  .object({
    AccessControlRegistry: z.string().optional(),
    Api3ServerV1: z.string().optional(),
    ProxyFactory: z.string().optional(),
    UsdcToken: z.string().optional(),
    PrepaymentDepository: z.string().optional(),
    chainId: z.number().positive(),
  })
  .strict();

const deploymentsSchema = z.record(z.string(), deploymentSchema);

export const isLocalhostAllowed = () => {
  const env = loadEnv();
  return env.NEXT_PUBLIC_ENVIRONMENT === 'development' || env.NEXT_PUBLIC_ENVIRONMENT === 'test';
};

export const getAllDeployments = () => {
  const deployments = isLocalhostAllowed() ? { ...deploymentsJson, ...deploymentsDevJson } : deploymentsJson;
  return deploymentsSchema.parse(deployments);
};

export const getDeployment = (networkName: string) => {
  return getAllDeployments()[networkName];
};

export const getNetworkNameByChainId = (chainId: number) => {
  return Object.entries(getAllDeployments()).find((chain) => chain[1].chainId === chainId)?.[0] ?? null;
};

export const getPrepaymentDepositoryNetworks = () => {
  const supportedChains = Object.entries(getAllDeployments())
    .filter(([, deployment]) => {
      return !!deployment.PrepaymentDepository;
    })
    .map(([networkName]) => networkName);

  return supportedChains;
};

export const isPrepaymentDepositoryNetwork = (chainId: number) => {
  const network = getNetworkNameByChainId(chainId);
  if (!network) return false;

  return getPrepaymentDepositoryNetworks().includes(network);
};

export const getPrepaymentDepositoryAddress = (chainId: number) => {
  const network = getNetworkNameByChainId(chainId);
  if (!network || !isPrepaymentDepositoryNetwork(chainId)) throw new Error(`Unsupported network ${chainId}`);

  const deployment = getDeployment(network);
  const address = deployment.PrepaymentDepository;
  if (!address) throw new Error('Expected PrepaymentDepository contract deployment to exist');
  return address;
};

export const getAccessControlRegistryAddress = (chainId: number) => {
  const network = getNetworkNameByChainId(chainId);
  if (!network || !isPrepaymentDepositoryNetwork(chainId)) throw new Error(`Unsupported network ${chainId}`);

  const deployment = getDeployment(network);
  const address = deployment.AccessControlRegistry;
  if (!address) throw new Error('Expected AccessControlRegistry contract deployment to exist');
  return address;
};

export const getTokenAddress = (chainId: number) => {
  const network = getNetworkNameByChainId(chainId);
  if (!network || !isPrepaymentDepositoryNetwork(chainId)) throw new Error(`Unsupported network ${network}`);

  const deployment = getDeployment(network);
  const address = deployment.UsdcToken;
  if (!address) throw new Error('Expected Token contract deployment to exist');
  return address;
};
